export function enviarArquivos(
  id_imovel,
  matricula_atual,
  kml = null,
  rinex = null
) {
  return {
    type: "@acervo/ENVIAR_ARQUIVOS",
    payload: { id_imovel, kml, rinex, matricula_atual },
  };
}

export function verificarMatricula(matricula_atual) {
  return {
    type: "@acervo/VERIFICAR_MATRICULA",
    payload: { matricula_atual },
  };
}

export function matriculaExiste(matricula_existe, permissao, matricula) {
  return {
    type: "@acervo/MATRICULA_EXISTE",
    payload: { matricula, matricula_existe, permissao },
  };
}

export function analisarKML(kml = null) {
  return {
    type: "@acervo/ANALISAR_KML",
    payload: { kml },
  };
}

export function enviarElementos(arquivos) {
  return {
    type: "@acervo/ENVIAR_ELEMENTOS",
    payload: { arquivos },
  };
}

export function baixarElemento(local) {
  return {
    type: "@acervo/BAIXAR_ELEMENTOS",
    payload: { local },
  };
}

export function addKMLComparacao(plantaFake) {
  return {
    type: "@acervo/KML_COMPARACAO",
    payload: { plantaFake },
  };
}
